const Helpers = {
  delay: (() => {
    let timer;
    return (cb, time = 500) => {
      clearTimeout(timer);
      timer = setTimeout(() => cb(), time);
    };
  })(),

  getSpaces(item) {
    return item.match(/[^\S]*/)[0].length;
  },

  gherkinConverter(txt) {
    const linesGroup = txt.split(/[\n\r]/g);
    const result = new Map();
    let block = [];

    const createLine = (line) => {
      const level = this.getSpaces(line);
      block[level] = line.trim();
      (
        new Array(level)
          .fill(null)
          .reduce((arr, nul, idx) => arr.get(block[idx]), result) || result
      ).set(block[level], new Map());
    };

    linesGroup.forEach(createLine);
    return result;
  },

  generateCourseHTML(map, level) {
    return (function go(map, level) {
      let res = `<ul class="${level === 0 ? 'list ' : ''}level-${level}">`;
      for (let item of map) {
        res += `<li>
                    ${level === 0 ? `<h3>${item[0]}</h3>` : item[0]}
                    ${item[1].size ? go(item[1], level + 1) : ''}
                </li>`;
      }
      return res + `</ul>`;
    })(this.gherkinConverter(map), level);
  },

  renderMenu(container, data, editable = false) {
    data.sort((a, b) => a.name > b.name);
    container.innerHTML = `
                ${data
                  .map((item) => {
                    return `<li class="list-inline-item">
                    <a data-id=${item.id}
                                itemprop="url"
                                href="#"
                                contenteditable="${editable}"
                                class="link">
                                ${item.name}
                        </a></li>`;
                  })
                  .join('')}`;
  },

  setMenuBtnActive(container, target) {
    const btnActive = container.querySelector('.btn-active');
    btnActive && btnActive.classList.remove('btn-active');
    target.classList.add('btn-active');
  },

  getQueryParams() {
    return ((params) => {
      return params
        ? params[0]
            .substr(1)
            .split('&')
            .map((v) => v.split('='))
            .reduce((a, p) => Object.assign({}, a, { [p[0]]: p[1] }), {})
        : {};
    })(/\?.*/.exec(location.href));
  },

  blockUntilEvent(target, event) {
    const promises = target.map((el) => {
      return new Promise((resolve) =>
        el.addEventListener(event, resolve, {
          // For simplicity, we will assume passive listeners.
          // Feel free to expose this as a configuration option.
          passive: true,
          // It is important to only trigger this listener once
          // so that we don't leak too many listeners.
          once: true,
        })
      );
    });
    return Promise.all(promises);
  },

  mapToEntities: (arr, key) => {
    return arr.reduce((acc, item) => {
      return { ...acc, [item[key]]: item };
    }, {});
  },

  get args() {
    const args = process.argv.filter((item) => item.startsWith('-'));
    const argsToObj = args.reduce((acc, arg) => {
      const item = arg.split('=');
      return { ...acc, [item[0].substring(1)]: item[1] };
    }, {});
    return argsToObj;
  },
};

module.exports = Helpers;
